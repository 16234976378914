@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  border: none;
  box-sizing: border-box;
  text-decoration: none;
  font-family: Pretendard Variable;
}

::-webkit-scrollbar {
  padding-top: 20px;
  width: 0px; /* 수직 스크롤바의 너비 */
  height: 0px; /* 수평 스크롤바의 높이 */
}

/* 스크롤바 트랙 (스크롤바의 배경) */
::-webkit-scrollbar-track {
  background: transparent; /* 트랙의 배경색 */
  border-radius: 10px; /* 트랙의 둥근 모서리 */
}

/* 스크롤바 핸들 (스크롤바의 움직이는 부분) */
::-webkit-scrollbar-thumb {
  background: #888; /* 핸들의 배경색 */
  border-radius: 10px; /* 핸들의 둥근 모서리 */
}

/* 핸들에 마우스를 올렸을 때의 스타일 */
::-webkit-scrollbar-thumb:hover {
  background: #555; /* 핸들에 마우스를 올렸을 때의 배경색 */
}
